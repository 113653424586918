import { Chip, Divider, Grid } from "@mui/material";
import Patr1 from "../../../parceiros/patr1.png";
import Patr2 from "../../../parceiros/patr2.png";
import Patr3 from "../../../parceiros/patr3.png";
import Patr4 from "../../../parceiros/patr4.png";
import Patr5 from "../../../parceiros/patr5.png";
import Patr6 from "../../../parceiros/patr6.png";
import styled from "styled-components";
import { COLOR_PRIMARY } from "../../../theme/styles";

const GridContainer = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
`

const GridItem = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Patrocinadores = (props: any) => {
    return (
        <GridContainer container spacing={2}>
            <GridItem item xs={12}>
                <Divider style={{ width: '100%' }} >
                    <Chip label="Apoio" size="small" sx={{ backgroundColor: COLOR_PRIMARY, color: 'white' }}></Chip>
                </Divider>
            </GridItem>
            <GridItem item xs={4}>
                <img src={Patr1} alt="Patrocinador 1" style={{ maxHeight: '75px', width: 'auto' }} />
            </GridItem>
            <GridItem item xs={4}>
                <img src={Patr2} alt="Patrocinador 2" width={'100%'} />
            </GridItem>
            <GridItem item xs={4}>
                <img src={Patr3} alt="Patrocinador 3" width={'100%'} />
            </GridItem>
            <GridItem item xs={4}>
                <img src={Patr4} alt="Patrocinador 4" width={'100%'} />
            </GridItem>
            <GridItem item xs={4}>
                <img src={Patr5} alt="Patrocinador 5" width={'100%'} />
            </GridItem>
            <GridItem item xs={4}>
                <img src={Patr6} alt="Patrocinador 6" width={'100%'} style={{ maxHeight: '70px', width: 'auto' }} />
            </GridItem>
        </GridContainer>
    )
}