import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

export function isMobileDevice() {
    return window.matchMedia('(max-width: 767px)').matches;
};

export function setLocalStorage(prefix: any, data: any) {
    localStorage.setItem(prefix, JSON.stringify(data));
}

export function getLocalStorage(prefix: any) {
    const json = localStorage.getItem(prefix)

    if (!json) {
        return null;
    }

    const result = JSON.parse(json)

    return result ?? null;
}

export function copyClipboard(value: any) {
    copy(value);
    toast.success('Copiado com sucesso.');
}

export const formatedPrice = (valor: Number) => {
    if (String(valor) === '')
        return '';

    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}