import { Button, ButtonProps } from 'react-bootstrap';
import { COLOR_PRIMARY } from '../../theme/styles';

export const VbButton = (props: ButtonProps) => {
    return (
        <Button
            {...props}
            style={{
                ...props.style,
                backgroundColor: COLOR_PRIMARY,
                borderColor: COLOR_PRIMARY,
                fontWeight: 600
            }}
            onFocus={(e: any) => {
                e.target.style.boxShadow = `0 0 0 0.05rem ${COLOR_PRIMARY}80, 0 0 0 0.1rem ${COLOR_PRIMARY}40`;
            }}
            onBlur={(e: any) => {
                e.target.style.boxShadow = 'none';
            }}
        />
    )
}
