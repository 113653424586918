import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { VbButton } from "../VbButton";
import { COLOR_PRIMARY } from "../../theme/styles";
import "./styles.css";
import { NumericFormat } from 'react-number-format';

const VbFormControl = styled(Form.Control)`
    &:focus {
        border-color: ${COLOR_PRIMARY};
        box-shadow: 0 0 0 0.05rem ${COLOR_PRIMARY}80,
                    0 0 0 0.1rem ${COLOR_PRIMARY}40;
    }
`;

const VbTextFixed = styled(InputGroup.Text)`
`
const VbFormLabel = styled(Form.Label)`
    margin-left: .375rem;
    margin-bottom: .275rem;  
    font-size: .875rem;
    font-weight: bold;
`
const VbFormFeedback = styled(Form.Control.Feedback)`
    margin: 0;
    font-size: .785rem;
    font-weight: 500;
`

interface VbInputProps {
    id: any;
    type: any;
    label: String;
    placeholder?: any;
    defaultValue?: any;
    onChange?: any;
    feedback?: String;
    textfixed?: String;
    required?: Boolean;
    disabled?: Boolean;
    md?: String;
    ref?: any;
    mask?: any;
    step?: any;
}

export const VbInput: React.FC<VbInputProps> = forwardRef((props: VbInputProps, ref: any) => {
    const [value, setValue] = useState<any>();
    const [showPassword, setShowPassword] = useState<any>();
    const [error, setError] = useState<any>();

    function getValue() {
        if (props.type)
            return value.replace('R$ ', '').replace(',', '.')
        else
            return value;
    }

    useImperativeHandle(ref, () => ({
        getValue,
        setError
    }));

    function applyCustomMask(input: string, mask: any): string {
        if (!mask)
            return input;

        let result = '';
        let maskIndex = 0;

        for (let i = 0; i < input.length; i++) {
            const char = input[i];

            if (maskIndex >= mask.length) {
                break;
            }

            if (/\d/.test(char)) {
                while (maskIndex < mask.length && (mask[maskIndex] === '(' || mask[maskIndex] === ')' || mask[maskIndex] === ' ' || mask[maskIndex] === '-')) {
                    result += mask[maskIndex];
                    maskIndex++;
                }

                if (mask[maskIndex] === '9') {
                    result += char;
                    maskIndex++;
                }
            } else if (mask[maskIndex] === char) {
                result += char;
                maskIndex++;
            }
        }

        return result;
    }

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const inputValue = e.target.value;
        const maskedValue = applyCustomMask(inputValue, props?.mask);
        setValue(maskedValue);

        if (error) {
            setError(false);
        }
    }

    return (
        <Form.Group as={Col} md={props?.md ? props.md : "12"} controlId={props.id} >
            <VbFormLabel>{(props?.required ? '*' : '') + props.label}</VbFormLabel>
            <InputGroup hasValidation>
                {props.type !== 'number' ? (
                    <VbFormControl
                        disabled={props.disabled ? true : false}
                        type={props.type !== 'password' ? props.type : showPassword ? 'text' : 'password'}
                        placeholder={props.placeholder}
                        value={value}
                        onChange={onChange}
                        required={props.required}
                        isInvalid={error}
                        spellCheck={false}
                    />
                ) : (
                    <NumericFormat
                        value={value}
                        customInput={VbFormControl}
                        decimalSeparator={','}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        prefix="R$ "
                        spellCheck={false}
                        required={props.required}
                        isInvalid={error}

                        style={{ textAlign: 'right' }}
                        onChange={onChange}
                        className={error ? 'is-invalid' : ''}
                    />
                )}

                {props.type === 'password' &&
                    <VbButton
                        style={{
                            display: 'flex',
                            borderTopRightRadius: '4px',
                            borderBottomRightRadius: '4px',
                            fontSize: '0.875rem',
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <VisibilityOffIcon style={{ fontSize: "1.2rem" }} /> : <VisibilityIcon style={{ fontSize: "1.2rem" }} />}
                    </VbButton>
                }

                <VbFormFeedback type="invalid">
                    {!error && (props.feedback ? props.feedback : props.required ? 'Campo obrigatório.' : '')}
                </VbFormFeedback>

                {props.textfixed &&
                    <VbTextFixed id="basic-addon2">
                        {props.textfixed}
                    </VbTextFixed>}
            </InputGroup>
        </Form.Group>
    );
});
