import { Doacao } from "./pages/Doacao";
import { VbNotification } from "./components/VbNotification";
import TagManager from 'react-gtm-module';
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-TKZBB2D2'
    }

    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <div>
      <Doacao />
      <VbNotification />
    </div>
  );
}

export default App;
