import { Row } from "react-bootstrap"
import { FaPix } from "react-icons/fa6";
import styled from "styled-components";
import { VbButton } from "../../../components/VbButton";
import { copyClipboard } from "../../../commons";
import { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import Compartilhe from "../../../Images/Compartilhe.jpeg";

const ImgQrCode = styled.img`
    width: 100%;
    max-height: 350px;
    max-width: 350px;
    margin: 10px;
`

const ImgShare = styled.img`
    width: 100%;
`;

export const QrCodeDoacao = (props: any) => {
    const auth = useAuth();

    const [pago, setPago] = useState(false);

    useEffect(() => {
        const checkPixStatus = async () => {
            if (!pago && props.pix.pixid) {
                try {
                    const response: any = await auth.get('/pix?pixid=' + props.pix.pixid);
                    if (response.data.dados.pixsituacao === 1) {
                        setPago(true);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        };

        if (!pago) {
            const intervalId = setInterval(checkPixStatus, 5000);
            return () => clearInterval(intervalId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pago]);

    return (
        <>
            {pago ?
                <>
                    <Row className="mb-4 justify-content-center">
                        <ImgShare src={Compartilhe} alt="Compartilhe" style={{ height: '100%', width: 'auto' }} />
                    </Row>
                    <Row className="justify-content-center p-3 pb-5">
                        <VbButton href="https://www.instagram.com/p/C3BrRj0JC4r/">COMPARTILHE</VbButton>
                    </Row>
                </> :
                <>
                    <Row className="justify-content-center">
                        <ImgQrCode alt='' src={'data:image/png;base64,' + props.pix.qrcode} />
                    </Row>
                    <Row className="mt-3 mb-3 justify-content-center">
                        <VbButton style={{ width: '70%' }} onClick={() => copyClipboard(props.pix.copiaecola)}>
                            <FaPix style={{ fontSize: '22px', marginRight: '10px' }} />
                            COPIA E COLA
                        </VbButton>
                    </Row>
                </>
            }
        </>
    )
}