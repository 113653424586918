import { Container, Form, ProgressBar, Row } from "react-bootstrap";
import { VbInput } from "../../components/VbInput";
import { useEffect, useRef, useState } from "react";
import { VbButton } from "../../components/VbButton";
import styled from "styled-components";
import LogoDoacaoPequena from "../../Images/LogoDoacaoPequena.png"
import { VbRadioButton } from "../../components/VbRadioButton";
import { toast } from "react-toastify";
import { formatedPrice, getLocalStorage, isMobileDevice, setLocalStorage } from "../../commons";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { QrCodeDoacao } from "./QrCode";
import moment from "moment";
import LogoDesenvolvedor from "../../Images/LogoBitSafira.png";
import "./styles.css";
import { Patrocinadores } from "./Patrocinadores";

const ContainerMain = styled.div`
    display: flex;
    min-height: 100vh;
    background: linear-gradient(180deg, #f1692a 0%, #b05f3a 100%);
    padding: ${isMobileDevice() ? '0' : '20px 0px'};
`;

const FormContainer = styled(Container)`
    max-width: ${isMobileDevice() ? '100%' : '600px'};
    height: auto;
    min-width: 250px;
    padding: ${isMobileDevice() ? '1.5rem 2rem' : '2rem 5rem 0rem 5rem'};
    display: flex;
    align-items: top;
    background-color: #fbf6f4;
    border-radius: ${isMobileDevice() ? '0px' : '7px'};
    box-shadow: 4px 0px 15px rgba(245, 245, 245, 0.3), 4px 0px 6px rgba(255, 255, 255, 0.2);
`;

const Logo = styled.img`
    width: 100%;
    padding: 0 2rem;
`;

const PanelLogo = styled.a`
    font-family: 'Ubuntu';
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #444;
    margin-bottom: 20px;
`

const LogoBitSafira = styled.img`
    width: 24px;
    margin-right: 5px;
`

const PanelDoacoes = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 14px;
    font-style: bold;
`

export const Doacao = () => {
    const auth = useAuth();

    const nomeRef = useRef<any>();
    const whatsAppRef = useRef<any>();
    const emailRef = useRef<any>();
    const valorRef = useRef<any>();

    const [serVb, setSerVb] = useState(true);
    const [novoAluno, setNovoAluno] = useState(true);
    const [validated, setValidated] = useState(false);
    const [pix, setPix] = useState<any>(null);
    const [disabled, setDisabled] = useState<any>(false);
    const [lastDoacao, setLastDoacao] = useState<any>();
    const [quantidade, setQuantidade] = useState<any>();

    useEffect(() => {
        const _pix = getLocalStorage('doarevida');

        if (_pix) {
            setPix(_pix);

            auth.get('/pix?pixid=' + _pix.pixid).then((response: any) => {
                if (response.data.dados.pixsituacao === 1)
                    setLastDoacao({
                        recebimento: response.data.dados.pixdatarecebimento,
                        valor: response.data.dados.pixvalor
                    })
            }).catch((error: any) => {
                console.log(error)
            })
        }

        auth.get('/doacoes/quantidade').then((response: any) => {
            setQuantidade(response.data.dados.quantidade)
        }).catch((error: any) => {
            console.log(error)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function sendDoacao() {
        if ((!serVb) && (Number(valorRef.current.getValue()) <= 0)) {
            setValidated(false);
            valorRef.current.setError(true);
            toast.error('Valor deve ser maior que zero.')
            return;
        }

        setDisabled(true);

        const body: any = {
            doacaoid: 0,
            doacaonome: nomeRef.current.getValue(),
            doacaowhatsapp: whatsAppRef.current.getValue(),
            doacaoemail: emailRef.current.getValue(),
            doacaovalor: (serVb ? novoAluno ? (69.9) : (39.9) : Number(valorRef.current.getValue()))
        }

        await auth.post('/doacao', body).then((response: any) => {
            setLocalStorage('doarevida', { pixid: response.data.dados.pix.pixid })
            setPix({
                pixid: response.data.dados.pix.pixid,
                qrcode: response.data.dados.pix.pixqrcode,
                copiaecola: response.data.dados.pix.pixcopiacola
            });
        }).catch((error: any) => {
            console.log(error)
            toast.error('Não foi possível gerar o QrCode no momento.');
        }).finally(() => {
            setDisabled(false);
        })
    }

    const handleSubmit = (e: any) => {
        const form = e.currentTarget;

        if (form.checkValidity() === true)
            sendDoacao()
        else
            setValidated(true);

        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <ContainerMain>
            <FormContainer className="d-flex flex-column">

                <Form noValidate validated={validated} className="w-100" onSubmit={handleSubmit}>
                    <Row className="mb-5 justify-content-center">
                        <Logo src={LogoDoacaoPequena} />
                    </Row>

                    {!pix?.qrcode ?
                        <fieldset disabled={disabled}>
                            {lastDoacao &&
                                <Row className="mb-2 justify-content-center" style={{ fontSize: '14px', color: '#084c26', fontWeight: 600, textAlign: 'center' }}>
                                    {'Última doação: ' + moment(lastDoacao.recebimento).format('DD/MM/YYYY HH:mm') + ' no valor de ' + formatedPrice(lastDoacao.valor)}
                                </Row>
                            }
                            <VbRadioButton className="pt-1" label="Quero Doar" name="group1" onClick={() => setSerVb(false)} />
                            <VbRadioButton className="mt-2 mb-2" label="Quero ser VbFit e Doar" name="group1" defaultChecked={true}  onClick={() => setSerVb(true)} />
                            {
                                serVb &&
                                <Row style={{ marginLeft: '20px' }}>
                                    <VbRadioButton label="Novo Aluno" name="group2" defaultChecked={true} onClick={() => setNovoAluno(true)} />
                                    <VbRadioButton className="mb-2" label="Ex-Aluno" name="group2" onClick={() => setNovoAluno(false)} />
                                </Row>
                            }
                            <Row className="mb-2 mt-2 justify-content-center">
                                <VbInput ref={nomeRef} id="nome" type="text" label="Nome" required />
                            </Row>
                            <Row className="mb-2 justify-content-center">
                                <VbInput ref={whatsAppRef} id="whatsapp" type="text" label="WhatsApp" required mask={'(99) 99999-9999'} />
                            </Row>
                            <Row className="mb-2 justify-content-center">
                                <VbInput ref={emailRef} id="email" type="text" label="E-mail" required />
                            </Row>
                            {!serVb &&
                                <Row className="mb-2 justify-content-center">
                                    <VbInput ref={valorRef} id="valor" type="number" label="Valor" required />
                                </Row>
                            }
                            <Row className="m-0 mt-4 mb-4 justify-content-center">
                                <VbButton type="submit" style={{ width: '100%' }}>{disabled ? 'Gerando QrCode...' : 'DOAR'}</VbButton>
                            </Row>
                            <ProgressBar animated color="orange" now={quantidade / 10} label={(quantidade / 10) + '%'} />
                            <PanelDoacoes>
                                META: {quantidade}/1000
                            </PanelDoacoes>
                            <Patrocinadores />
                            <PanelLogo href="https://bitsafira.com.br/">
                                <LogoBitSafira src={LogoDesenvolvedor} />
                                <div>
                                    <div style={{ fontSize: '10px' }}>Desenvolvido por</div>
                                    <div style={{ marginTop: '-5px' }}>BITSAFIRA</div>
                                </div>
                            </PanelLogo>
                        </fieldset> :
                        <QrCodeDoacao pix={pix} />
                    }
                </Form>
            </FormContainer>
        </ContainerMain >
    )
}