import { Api } from "../../api/address";

export async function GetRequest(url: string, token: any) {
    const config = {
   //     headers: { 'Token': token }
    }

    try {
        const response = await Api.get(url, config);
        return response;
    } catch (error: any) {
        return error
    }
}

export async function PostRequest(url: string, token: any, data: any) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
      //      'Token': token,
        }
    }

    try {
        const response = await Api.post(url, data, config);
        return response;
    } catch (error: any) {
        return error
    }
}